import React from "react";
import styled from 'styled-components';
import Marquee from "react-fast-marquee";

function Banner({ onIndex = false }) {
    const getText = (type) => {
        return (
            <BannerTextWrapper>
                <Dot />
                <p>Introducing {type}</p>
            </BannerTextWrapper>
        )
    }

    return (
        <BannerWrapper>

            <ScrollingWrapper>
                <BannerText>
                    <Marquee speed={40} gradient={false}>
                        {getText("Artists")}
                        {getText("Freelancers")}
                        {getText("Studios")}
                        {getText("Creators")}
                        {getText("Students")}
                        {getText("Artists")}
                        {getText("Freelancers")}
                        {getText("Studios")}
                        {getText("Creators")}
                        {getText("Students")}
                        {getText("Artists")}
                        {getText("Freelancers")}
                        {getText("Studios")}
                        {getText("Creators")}
                        {getText("Students")}
                        {getText("Artists")}
                        {getText("Freelancers")}
                        {getText("Studios")}
                        {getText("Creators")}
                        {getText("Students")}
                        {getText("Artists")}
                        {getText("Freelancers")}
                        {getText("Studios")}
                        {getText("Creators")}
                        {getText("Students")}
                    </Marquee>
                </BannerText>
            </ScrollingWrapper>
        </BannerWrapper>
    );
}

export default Banner;

const Dot = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid white;
    margin: 0 15px;
`;

const BannerWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #3D5D45;
    padding: 10px 0;
`;

const BannerTextWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const BannerText = styled.div`
    font-size: 14px;
    float: left;
    color: white;
    text-transform: uppercase;
    justify-content: center;
`;

const ScrollingWrapper = styled.div`
    
`;