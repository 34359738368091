import React from "react";
import styled from 'styled-components';
import { isMobile } from '.././helpers';
import Button from "./Button";

const atoms_icon_white = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629832321/introducing/icons/atoms-icon_cjz7au.svg';
const atoms_icon_black = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629832321/introducing/icons/atoms-icon-black_o2j362.svg';

class TopBar extends React.Component {
    state = {
        nav: false
    }
    componentDidMount() {
        const { changeOnScroll } = this.props;
        if (changeOnScroll) {
            window.addEventListener("scroll", this.handleScroll);
        }
    }
    componentWillUnmount() {
        const { changeOnScroll } = this.props;
        if (changeOnScroll) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const switchColorPosition = isMobile() ? 0.65 : 0.8;
        if (window.pageYOffset > (vh * switchColorPosition)) {
            if (!this.state.nav) {
                this.setState({ nav: true });
            }
        } else {
            if (this.state.nav) {
                this.setState({ nav: false });
            }
        }

    }

    scrollToBottom = () => {
        window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    render() {

        return (
            <TopbarWrapper>
                <TopBarContent>
                    <LogoWrapper href='/'>
                        {this.state.nav || !this.props.changeOnScroll ?
                            <>
                                <ImageWrapper src={atoms_icon_black} />
                                <WordMark style={{ color: 'black' }}>atoms</WordMark>
                            </> :
                            <>
                                <ImageWrapper src={atoms_icon_white} />
                                <WordMark>atoms</WordMark>
                            </>
                        }
                    </LogoWrapper>
                    {!isMobile() && <ButtonWrapper>
                        <RightButton>
                            {this.state.nav || !this.props.changeOnScroll ?
                                <Button
                                    color='green'
                                    onClick={this.scrollToBottom}
                                    label="Subscribe"
                                /> :
                                <Button
                                    color='white'
                                    onClick={this.scrollToBottom}
                                    label="Subscribe"
                                />
                            }
                        </RightButton>
                        {this.state.nav || !this.props.changeOnScroll ?
                            <Button
                                color='green'
                                label="Nominate"
                                href="https://atoms.typeform.com/to/V1lR1tJn"
                                newTab={true}
                            /> :
                            <Button
                                color='white'
                                label="Nominate"
                                href="https://atoms.typeform.com/to/V1lR1tJn"
                                newTab={true}
                            />
                        }
                    </ButtonWrapper>}

                </TopBarContent>

            </TopbarWrapper>
        )
    }
}

export default TopBar;

const TopbarWrapper = styled.div`
    width: 100%;
    position: fixed;
    z-index: 5;
    margin-top: 50px;
    padding: 15px 50px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        padding: 0;
    }
`;

const RightButton = styled.div`
    margin-right: 24px;
`;

const TopBarContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 1800px;

    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const WordMark = styled.p`
    font-size: 40px;
    font-weight: 600;
    color: white;
    line-height: 1;

    @media only screen and (max-width: 600px) {
        font-size: 30px;
    }
`;

const ImageWrapper = styled.img`
    height: 35px;
    margin-right: 8px;

    @media only screen and (max-width: 600px) {
        height: 25px;
    }
`
const LogoWrapper = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
`;