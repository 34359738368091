import React from "react";
import styled from 'styled-components';

const BUTTON_COLORS = {
    green: {
        backgroundColor: '#3D5D45',
        textColor: 'white'
    },
    white: {
        backgroundColor: 'white',
        textColor: '#3D5D45'
    },
    neon: {
        backgroundColor: '#EEFF41',
        textColor: 'black'
    }
}

const BUTTON_SIZES = {
    small: {
        fontSize: 16
    },
    medium: {
        fontSize: 20
    }
}

function Button({ label, color = 'white', href, onClick, newTab, size }) {
    const getButtonColors = () => {
        if (color === 'green') {
            return BUTTON_COLORS['green'];
        } else if (color === 'neon') {
            return BUTTON_COLORS['neon'];
        }

        return BUTTON_COLORS['white'];
    }

    const getButtonSize = () => {
        if (size === 'small') {
            return BUTTON_SIZES['small']
        }
        return BUTTON_SIZES['medium'];
    }

    const button_colors = getButtonColors();
    const button_size = getButtonSize();

    const button_styles = {
        backgroundColor: button_colors['backgroundColor'],
        color: button_colors['textColor'],
        fontSize: button_size['fontSize']
    }

    if (onClick && !href) {
        return (
            <ButtonWrapper
                onClick={onClick}
                style={button_styles}
            >
                {label}
            </ButtonWrapper>
        );
    }

    // new tab link button
    if (newTab) {
        return (
            <LinkWrapper href={href} target="_blank" rel="noreferrer">
                <ButtonWrapper style={button_styles}>
                    {label}
                </ButtonWrapper>
            </LinkWrapper>
        );
    }

    // default
    return (
        <LinkWrapper href={href}>
            <ButtonWrapper style={button_styles}>
                {label}
            </ButtonWrapper>
        </LinkWrapper>
    );
}

export default Button;

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
`;

const ButtonWrapper = styled.button`
    padding: 12px 24px;
    cursor: pointer;
    border: none;
    outline: none;

    :hover {
        background-color: rgba(0, 0, 0, .5)
    }
`;











