import React from "react";
import styled from 'styled-components';
const coming_soon_image = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629832288/introducing/coming-soon_tyvetn.png';

class ComingSoonCard extends React.Component {
    render() {

        return (
            <ProjectCardWrapper>
                <ProjectCard>
                    <div stye={{ width: '100%' }}><ImageWrapper><CreatorImage src={coming_soon_image} /></ImageWrapper></div>

                    <TextWrapper>

                    </TextWrapper>
                </ProjectCard>
            </ProjectCardWrapper>
        )
    }
}

export default ComingSoonCard;


const TextWrapper = styled.div`
    padding-top: 10px;


    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ProjectCardWrapper = styled.div`
    display: flex;
    padding: 10px;
    margin-bottom: 30px;
    width: 330px;

    @media only screen and (max-width: 600px) {
        width: 50%;
        margin-bottom: 20px;
    }
`;

const ProjectCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CreatorImage = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const ImageWrapper = styled.div`
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: 125%;
`;