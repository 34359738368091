import React from "react";
import styled from 'styled-components';
import TopBar from "../components/TopBar";
import FooterSection from "../components/FooterSection";

// images
const creator_header_image = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629829522/introducing/kevin_kuh_d15sqd.png';
const kevin_kuh_painting = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629829530/introducing/kevin_kuh_painting_avggnt.png';
const kevin_video = 'https://res.cloudinary.com/atoms-shoes/video/upload/v1629829519/introducing/kevin-kuh-video_i59mhb.mp4';
const kevin_video_cover = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629831397/introducing/cover_ge6pff.png';

function CreatorPage() {

    return (
        <>
            <TopBar changeOnScroll={false} />
            <PageWrapper>
                <ContentWrapper>

                    <SubText>Musician. Painter.</SubText>
                    <HeaderText>Kevin Kuh</HeaderText>
                    <CreatorImageWrapper>
                        <CreatorImage src={creator_header_image} />
                    </CreatorImageWrapper>

                    <BodyWraper>
                        <SubheaderText>
                            Introducing Kevin Kuh, our first partner for the INTRODUCING series.
                        </SubheaderText>
                        <BodyText>
                            A few seconds into Kevin’s submission, we were in awe at the passion that came right through - and we were pleasantly surprised by the
                            thoughtfulness too. Kevin made this painting, inspired by the colors of Atoms.
                        </BodyText>
                        <BodyMediaWrapper>
                            <BodyImage src={kevin_kuh_painting} />
                        </BodyMediaWrapper>
                        <BodyText>
                            "The painting is 48'' x 60'' large and is composed of accrylic paints and oil pastels and the color palette was inspired by
                            some of the iconic colors of the Atoms shoes. I'm very hapy to share the final product of my painting for Atoms Introducing. I think
                            it turned out so well and I am very proud of this piece"
                        </BodyText>
                        <BodyMediaWrapper>
                            <BodyVideo
                                src={kevin_video}
                                poster={kevin_video_cover}
                                controls
                                playsinline />
                        </BodyMediaWrapper>
                        <BodyText>
                            "My work takes me all over the place. Whether at a studio making music, dancing on stage while performing
                            a show, outside making a large painting, or traveling and taking photos, I'm usually on the move. I do a lot
                            of different things and I love it. It's motivating and exciting to take on new challenges and work in different
                            environments with different people. Im also always looking for a new opportunity to apply my skills and be inspired
                            by whats around me"
                        </BodyText>
                        <BodyText>
                            "Cooking has honestly been very inspiring lately. It's a different outlet and the process and reward have provided great joy
                            lately. For music, I've been listening to ford., Lizzy McAlpine, Sylvan Esso and Bon Iver."
                        </BodyText>

                        <BodyText>
                            In addition to his painting Kevin, alongside Pool Cosby, put on a musical performance at our Warehouse in the Navy Yard. Due to
                            COVID-19 we were only able to invite a few neighbours in the building but you can watch a recording of livestream on
                            our <BodyLink href='https://www.instagram.com/p/CS0EO4YJ15L/' target="_blank" rel="noreferrer">Instagram</BodyLink>.
                        </BodyText>

                        <BodyText>
                            You can find more of Kevin's work and contact him from his website - tell him Atoms sent
                            you: <BodyLink href='https://www.kevinkuh.com/' target="_blank" rel="noreferrer">www.kevinkuh.com</BodyLink>
                        </BodyText>

                    </BodyWraper>

                </ContentWrapper>
            </PageWrapper>
            <FooterSection />
        </>

    );
}

export default CreatorPage;

const PageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 100px 50px;

    @media only screen and (max-width: 600px) {
        padding: 100px 20px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 150px;
    align-items: center;

    @media only screen and (max-width: 600px) {
        margin-top: 50px;
    }
`;

const CreatorImage = styled.img`
    border: 5px solid #3D5D45;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const BodyWraper = styled.div`
    width: 600px;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const CreatorImageWrapper = styled.div`
    margin: 100px 0;

    @media only screen and (max-width: 600px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

const HeaderText = styled.p`
    font-size: 130px;
    color: #3D5D45;
    line-height: 130px;
    text-align: center;

    @media only screen and (max-width: 600px) {
        font-size: 75px;
        line-height: 1;
    }
`;

const SubText = styled.p`
    font-size: 24px;
    color: #6F6F6F;
    line-height: 1;
    text-transform: uppercase;
    padding: 15px 0;
    font-weight: bold;
    text-align: center;

    @media only screen and (max-width: 600px) {
        font-size: 16px;   
    }
`;

const SubheaderText = styled.p`
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    padding: 10px 0;
`;

const BodyText = styled.p`
    font-size: 16px;
    line-height: 1.5;
    padding: 16px 0;
`;

const BodyMediaWrapper = styled.div`
    width: 100%;
    margin: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BodyImage = styled.img`
    object-fit: cover;
    width: 100%;

    @media only screen and (max-width: 600px) {
        width: 80%;
    }
`

const BodyVideo = styled.video`
    width: 100%;

    @media only screen and (max-width: 600px) {
        width: 80%;
    }
`;

const BodyLink = styled.a`
    color: inherit;

    :hover {
        color: blue;
    }
`;