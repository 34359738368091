import React from "react";
import styled, { keyframes } from 'styled-components';

const star_svg = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629832292/introducing/star_eggtvc.svg';

function Header() {


    return (
        <HeaderWrapper>
            <TextWrapper>
                <HeaderTextWrapper>
                    <HeaderImageWrapper><HeaderImage src={star_svg} /></HeaderImageWrapper>
                    <HeaderText>INTRODUCING</HeaderText>
                </HeaderTextWrapper>
                <SubTextWrapper>
                    <HeaderSubText>Giving creators their first shot</HeaderSubText>
                </SubTextWrapper>
            </TextWrapper>
        </HeaderWrapper>

    );
}

export default Header;

/* Header Styles */

const rotate = keyframes`
  0% {
    transform: rotate3d(0);
    -webkit-transform: rotate3d(0);
    -ms-transform:rotate3d(0);
  }
  50% {
    transform: rotate3d(0.1, 1, 0.1, 180deg);
    -webkit-transform: rotate3d(0.1, 1, 0.1, 180deg);
    -ms-transform:rotate3d(0.1, 1, 0.1, 180deg);
  }
  100% {
    transform: rotate3d(0.1, 1, 0.1, 360deg);
    -webkit-transform: rotate3d(0.1, 1, 0.1, 360deg);
    -ms-transform:rotate3d(0.1, 1, 0.1, 360deg);
`;

const HeaderImageWrapper = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items:center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`


const HeaderImage = styled.img`
    width: 800px;
    animation: ${rotate} 10s linear infinite;

    @media only screen and (max-width: 600px) {
        width: 400px;
        max-width: 100vw;
    }
`;

const HeaderWrapper = styled.div`
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
    height: 90vh;
    background: #3D5D45;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 50px;

    @media only screen and (max-width: 600px) {
        padding: 0 20px;
        min-height: auto;
        height: 70vh;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const HeaderTextWrapper = styled.div`
    position: relative;
`;

const SubTextWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        align-items: center;
    }
`;

const HeaderText = styled.p`
    font-size: 130px;
    font-weight: 600;
    color: #EEFF41;
    line-height: 130px;

    @media only screen and (max-width: 600px) {
        font-size: 12vw;
        line-height: 1;
    }
`;

const HeaderSubText = styled.p`
    color: #EEFF41;
    font-family: Graphik;
    font-size: 25px;

    @media only screen and (max-width: 600px) {
        align-items: center;
        font-size: 5vw;
        line-height: 1;
    }
`;




