import React from "react";
import styled from 'styled-components';
import Button from "./Button";

function AboutSection() {


    return (
        <AboutSectionWrapper>
            <AboutSectionContent>
                <MainText>
                    We know what it’s like to be starting out.
                    <br /><br />
                    And we know what it’s like to be given a chance.
                    <br /><br />
                    So we’re commiting to working with at least once freelancer, new studio, student, or artist and be their first paid client.
                    <br /><br />
                    If things turn out the way we think they will, we’ll continue to support and endorse these creators.
                    <br /><br />
                    If you know someone who is just starting up and deserves a chance, introduce them to us, so we can introduce them to the world.
                </MainText>
                <ButtonWrapper>
                    <Button
                        color='green'
                        label="Nominate a Creator"
                        href="https://atoms.typeform.com/to/V1lR1tJn"
                        newTab={true}
                    />
                </ButtonWrapper>
            </AboutSectionContent>

        </AboutSectionWrapper>

    );
}

export default AboutSection;

/* Header Styles */


const AboutSectionWrapper = styled.div`
    padding: 150px 50px;
    background-color: #D7D8DA;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        padding: 75px 20px;
    }
`;


const AboutSectionContent = styled.div`
    width: 700px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 50px;
`;

const MainText = styled.p`
    font-size: 18px;
    line-height: 1.3;
    color: #3D5D45;
`;