import React, { useRef } from "react";
import styled from 'styled-components';
import CreatorCard from "./CreatorCard";
import ComingSoonCard from "./ComingSoon";

const creatorData2 = [
    {
        image: 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629829522/introducing/kevin_kuh_d15sqd.png',
        url: '/kevinkuh',
        title: 'Kevin Kuh',
        subtitle: 'Painter. Musician',
    },
]

function CreatorsSection() {
    const listWrapper = useRef();


    return (
        <CreatorsSectionWrapper>
            <CreatorSectionContent>
                <SectionTitle>
                    <SectionTitleText>Creators</SectionTitleText>
                </SectionTitle>
                <ProjectCarouselWrapper
                    ref={listWrapper}
                >
                    {creatorData2.map((item, index) => {
                        return (
                            <CreatorCard data={item} key={index} />)
                    })}
                    <ComingSoonCard />
                    <ComingSoonCard />
                </ProjectCarouselWrapper>
            </CreatorSectionContent>
        </CreatorsSectionWrapper>

    );
}

export default CreatorsSection;

/* Header Styles */
const SectionTitle = styled.div`
    display: flex;

    :after{
        margin-left: 10px;
        content: "";
        flex: 1 1;
        border-bottom:1px #3D5D45 solid;
        margin: auto;
      }
`;

const SectionTitleText = styled.p`
    font-size: 48px;
    color: #3D5D45;
    margin-right: 16px;

    @media only screen and (max-width: 600px) {
        font-size: 24px;
    }
`;
const ProjectCarouselWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: -10px;
    margin-right: -10px;

    @media only screen and (max-width: 600px) {;
        margin-top: 10px;
    }
`;

const CreatorsSectionWrapper = styled.div`
    padding: 150px 50px;
    display: flex;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
        padding: 75px 20px;
    }
`;

const CreatorSectionContent = styled.div`
    max-width: 1800px;
    width: 100%;
`;