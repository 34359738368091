import React from "react";
import styled from 'styled-components';
import Button from "./Button";

const atoms_icon_neon_svg = 'https://res.cloudinary.com/atoms-shoes/image/upload/v1629832321/introducing/icons/atoms-icon-neon_lkwl2j.svg';

function FooterSection() {


    return (
        <FooterSectionWrapper>
            <NewsletterSection>
                <EmailHeader>Sign up to recieve updates on INTRODUCING creators</EmailHeader>
                <EmailInputWrapper>
                    <EmailInput type="email" placeholder="Email.." />
                    <ButtonWrapper>
                        <Button
                            color='neon'
                            label="Subscribe"
                            onClick={() => { }}
                            size='small'
                        />
                    </ButtonWrapper>
                </EmailInputWrapper>
            </NewsletterSection>
            <FooterLogoWrapper>
                <LogoLinkWrapper href='https://www.atoms.com/'>
                    <IntroducingText>Introducing</IntroducingText>
                    <FooterLogo src={atoms_icon_neon_svg} />
                </LogoLinkWrapper>

            </FooterLogoWrapper>


        </FooterSectionWrapper >

    );
}

export default FooterSection;

/* Header Styles */

const FooterLogoWrapper = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    flex-direction: column;
`;

const LogoLinkWrapper = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    flex-direction: column;
`;


const IntroducingText = styled.p`
    text-transform: uppercase;
    font-weight: 600;
    color: #EEFF41;
    line-height: 1;
`;

const FooterLogo = styled.img`
    width: 25px;
    padding-top: 10px;
`;

const EmailInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const EmailHeader = styled.p`
    font-size: 24px;
    color: white;
    padding: 15px 0;
    line-height: 1.5;
`;

const EmailInput = styled.input`
    font-size: 16px;
    border-radius: 2px;
    border: none;
    padding: 12px;
    font-family: 'Graphik';
    outline: none;
    width: 100%;

`;

const NewsletterSection = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: 600px) { 
        width: 100%;
    }
}
    
`;

const FooterSectionWrapper = styled.div`
    padding: 100px 50px;
    background-color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        padding: 100px 20px;
        padding-bottom: 50px;
    }
`;

const ButtonWrapper = styled.div`
    margin-left: 16px;
`;

