import React from "react";
import styled from 'styled-components';

class CreatorCard extends React.Component {
    openCreatorPage = () => {
        const { data } = this.props;
        window.location = data.url;
    }

    render() {
        const { data } = this.props;
        return (
            <ProjectCardWrapper>
                <ProjectCard onClick={() => this.openCreatorPage()}>
                    <div stye={{ width: '100%' }}>
                        <ImageWrapper><CreatorImage src={data.image} /></ImageWrapper>

                    </div>
                    <TextWrapper>
                        <Title>
                            <LinkWrapper href={data.url} >
                                {data.title}
                            </LinkWrapper>
                        </Title>
                        <SubTitle>
                            {data.subtitle}
                        </SubTitle>

                    </TextWrapper>
                </ProjectCard>
            </ProjectCardWrapper>
        )
    }
}

export default CreatorCard;


const TextWrapper = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
`;

const Title = styled.p`
    font-weight: 600;
    font-size: 18px;
    color: black;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;


const SubTitle = styled.p`
    font-size: 16px;
    padding-top: 5px;
    color: #4E4E4E;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
`;


const ProjectCardWrapper = styled.div`
    display: flex;
    padding: 10px;
    margin-bottom: 30px;
    width: 330px;

    @media only screen and (max-width: 600px) {
        width: 50%;
        margin-bottom: 20px;
    }
`;

const ProjectCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
`;

const CreatorImage = styled.img`
    display: block;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
`;

const ImageWrapper = styled.div`
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: 125%;
`;