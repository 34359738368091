import React from "react";
import Home from "./screens/Home";
import KevinKuh from "./screens/KevinKuh";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/kevinkuh">
          <KevinKuh />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

