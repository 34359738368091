import React from "react";
import styled from 'styled-components'
import AboutSection from "../components/AboutSection";
import Banner from "../components/Banner";
import CreatorsSection from "../components/CreatorsSection";
import FooterSection from "../components/FooterSection";
import Header from "../components/Header";
import TopBar from "../components/TopBar";

function Home() {

    return (
        <HomeWrapper>
            <ContentWrapper>
                <TopBar changeOnScroll={true} />
                <Banner />
                <Header />
                <CreatorsSection />
                <AboutSection />
                <FooterSection />
            </ContentWrapper>
        </HomeWrapper>

    );
}

export default Home;

const HomeWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;